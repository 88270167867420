'use strict'

window.jQuery = require('jquery')
window.$ = window.jQuery
require('webpack-jquery-ui')
require('jquery-timepicker/jquery.timepicker')
window.bootstrap = require('bootstrap')
// https://github.com/uxsolutions/bootstrap-datepicker
require('bootstrap-datepicker')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.ja')
// https://github.com/snapappointments/bootstrap-select/
require('bootstrap-select')
require('chart.js')
// require('bootstrap-select/js/i18n/defaults-ja_JP.js')
window.$.fn.selectpicker.defaults = {
  noneSelectedText: '選択されていません',
  noneResultsText: '\'{0}\'に一致する結果がありません',
  countSelectedText: '{0}項目選択',
  maxOptionsText: ['制限に達しました(最大{n}{var})', 'グループの制限に達しました(最大{n}{var})', ['項目', '項目']],
  selectAllText: '全選択',
  deselectAllText: '全選択解除',
  multipleSeparator: ', '
}
// https://github.com/nakupanda/bootstrap3-dialog
window.BootstrapDialog = require('bootstrap4-dialog')
window.BootstrapDialog.DEFAULT_TEXTS['OK'] = 'OK'
window.BootstrapDialog.DEFAULT_TEXTS['CANCEL'] = '取消'
window.BootstrapDialog.DEFAULT_TEXTS['CONFIRM'] = '確認'

// https://github.com/wenzhixin/bootstrap-table
require('bootstrap-table')
require('bootstrap-table/src/locale/bootstrap-table-ja-JP.js')

window.tms = require('./tms')

$(document).ready(function() {
  window.init()
})

window.defaultCursor = function() {
  document.body.style.cursor = 'default'
}

window.waitCursor = function() {
  document.body.style.cursor = 'wait'
}

window.setInputValues = function($parent, json) {

  $.each(json, function(key, value) {
    $parent.find('[name="' + key + '"]').val(value).change()
  })
}

window.isValid = function(text) {

  if (0 < text.replace(/[\s\r\n\t]+/g, '').length) {
    return true
  }
  return false
}

function getForm($target) {

  let $form = $target.attr('form')
  if (!$form) {
    $form = $target.parents('form')
  }
  let method = $target.attr('formmethod')
  if (method) {
    $form.attr('method', method)
  }
  let action = $target.attr('formaction')
  if (action) {
    $form.attr('action', action)
  }
  let enctype = $target.attr('formenctype')
  if (enctype) {
    $form.attr('enctype', enctype)
  }
  return $form
}

const dateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

window.getDateTimeText = function(date) {

  if (!date) {
    date = new Date()
  }
  return date.toLocaleTimeString('ja-JP', dateTimeFormatOptions)
}

window.init = function() {

  window.defaultCursor()
  $('.selectpicker').selectpicker()
  $('[data-toggle="tooltip"]').tooltip()

  $('[data-toggle="offcanvas"]').click(function () {
    $('.row-offcanvas').toggleClass('active')
  })

  $('[data-toggle="clear"]').click(function () {
    let target = $(this).data('target')
    if (target) {
      window.clearForm($(target))
    }
  })

  $('.input-group.date').datepicker({
    format: 'yyyy-mm-dd',
    endDate: '9999-12-31',
    language: 'ja',
    orientation: 'bottom',
    autoclose: true,
    clearBtn: true
  })

  $('#paginationSize').on('hidden.bs.dropdown', function(e) {
    let $input = $(e.target).find('input[name="size"]')
    $input.closest('form').submit()
  })

  $('.input-datepicker').datepicker({
    format: 'yyyy-mm-dd',
    endDate: '9999-12-31',
    language: 'ja',
    orientation: 'bottom auto',
    autoclose: true,
    clearBtn: true,
  })

  $('.custom-file-input').on('change', function(){
    $(this).next('.custom-file-label').html($(this)[0].files[0].name)
  })
  $('.reset').click(function(){
    $(this).parent().prev().children('.custom-file-label').html('ファイル選択')
    $('#preview').remove()
    $('.custom-file-input').val('')
  })

  $('[type="submit"][data-toggle="confirm-dialog"]').each(function(index, element) {
    let $target = $(element)
    let title = $target.data('dialog-title')
    let message = $target.data('dialog-message')
    let submitLabel = $target.data('dialog-submit-label')
    if (!submitLabel) {
      submitLabel = window.BootstrapDialog.DEFAULT_TEXTS['CONFIRM']
    }
    let closeLabel = $target.data('dialog-close-label')
    if (!closeLabel) {
      closeLabel = window.BootstrapDialog.DEFAULT_TEXTS['CANCEL']
    }
    $target.on('click', function(e) {
      e.preventDefault()
      let $source = $(e.currentTarget)
      window.confirmSubmit(getForm($source), title, message, submitLabel, closeLabel)
      return false
    })
  })
}

window.changePage = function (number) {

  let $input = $('input[name="page"]')
  $input.val(number)
  $input.closest('form').submit()
}

window.confirmSubmit = function($form, title, message, submitLabel = window.BootstrapDialog.DEFAULT_TEXTS['CONFIRM'], closeLabel = window.BootstrapDialog.DEFAULT_TEXTS['CANCEL']) {

  window.BootstrapDialog.show({
    title: title,
    message: message,
    buttons: [{
      label: submitLabel,
      cssClass: 'btn-primary',
      action: function(dialog) {
        dialog.close()
        $form.submit()
      }
    },{
      label: closeLabel,
      action: function(dialog) {
        dialog.close()
      }
    }]
  })
}

window.confirmSubmit = function(source, title, message) {

  let $source = $(source)
  let $form = $source.closest('form')
  window.BootstrapDialog.show({
    title: title,
    message: message,
    buttons: [{
      label: '実行',
      cssClass: 'btn-primary',
      action: function(dialog) {
        dialog.close()
        $form.submit()
      }
    },{
      label: '取消',
      action: function(dialog) {
        dialog.close()
      }
    }]
  })
}

window.confirmHref = function(source, title, message) {

  let $source = $(source)
  window.BootstrapDialog.show({
    title: title,
    message: message,
    buttons: [{
      label: '実行',
      cssClass: 'btn-primary',
      action: function(dialog) {
        dialog.close()
      }
    },{
      label: '取消',
      action: function(dialog) {
        dialog.close()
      }
    }]
  })
}

window.submitAjaxModalForm = function($form, done, fail, always, xhr) {

  window.waitCursor()
  window.cleanAlerts($form)
  if (!done) {
    done = function(body) {
      if(window.displayAlerts(body, $form) <= 0) {
        $form.closest('.modal').modal('hide')
        location.reload()
      }
    }
  }
  if (!fail) {
    fail = function(response) {
      console.log(response)
      window.displayAlerts(response.responseJSON, $form)
    }
  }
  let enctype = $form.attr('enctype')
  let settings = {
    type: $form.attr('method'),
    url: $form.attr('action'),
    enctype: enctype,
    dataType: 'json',
    cache : false
  }
  if (xhr) {
    settings.xhr = xhr
  }
  if (enctype === 'multipart/form-data') {
    settings.processData = false
    settings.contentType = false
    settings.data = new FormData($form[0])
  } else {
    settings.data = $form.serialize()
  }
  let promise = $.ajax(settings)
  promise.then(done).fail(fail).always(function() {
    window.defaultCursor()
    if (typeof always === 'function') {
      always()
    }
  })
  return false
}

window.displayAlerts = function(body, $parent) {

  let count = 0
  if (!$parent) {
    $parent = $('form')
  }
  if(body && 'errors' in body) {
    $.each(body.errors, function(index, error) {
      let $alert = $('<span class="label badge-danger" role="alert"></span>')
      $alert.text(error.message)
      let $target = $parent.find('[data-alert*="' + error.field + '"]')
      if (0 < $target.length) {
        $target.append($alert)
      } else {
        $parent.find('[name="' + error.field + '"]').parent().append($alert)
      }
      count++
    })
  }
  return count
}

window.clearForm = function($parent) {

  if (!$parent) {
    $parent = $('form')
  }
  $parent.find('select').val(null)
  $parent.find(':checkbox').prop('checked', false)
  $parent.find(':text').val(null)
  $parent.find(':password').val(null)
  $parent.find('.btn-group .active').removeClass('active')
  $parent.find('.selectpicker').selectpicker('render')
}

window.setInputValues = function($parent, json) {

  $.each(json, function(key, value) {
    $parent.find('[name="' + key + '"]').val(value).change()
  })
}

window.cleanAlerts = function(target) {

  let $parent
  if (target) {
    $parent = $(document)
  } else {
    $parent = $(target)
  }
  let $alerts = $parent.find('[role="alert"]')
  $alerts.remove()
  $parent.find('.is-invalid').removeClass('is-invalid')
}

window.cleanTableBody = function($parent) {

  if (!$parent) {
    $parent = $('table')
  }
  $parent.find('tbody > tr').empty()
}

window.deleteTableRow = function($source) {

  let $target
  if ($source.target) {
    $target = $source.target
  } else {
    $target = $($source)
  }
  let $tr = $target.closest('tr')
  $tr.remove()
}

window.Progress = class Progress {

  constructor($parent) {

    this.$progress = $parent.find('.progress')
    this.init()
  }

  init() {

    this.hide()
    this.remove()
    let $bar = $('<div>')
    $bar.addClass('progress-bar')
    $bar.attr('role', 'progressbar')
    $bar.attr('aria-valuenow', '0')
    $bar.attr('aria-valuemin', '0')
    $bar.attr('aria-valuemax', '0')
    $bar.css('width: 0%;')
    $bar.text('0%')
    this.$progress.append($bar)
    return this
  }

  show() {

    this.$progress.show()
    return this
  }

  hide() {

    this.$progress.hide()
    return this
  }

  remove() {

    let $bar = this.$progress.find('.progress-bar')
    if ($bar) {
      $bar.remove()
    }
    return this
  }

  update(current, capacity) {

    let $bar = this.$progress.find('.progress-bar')
    if ($bar) {
      $bar.attr('aria-valuenow', current)
      $bar.attr('aria-valuemax', capacity)
      let percent = (current / capacity * 100).toFixed() + '%'
      $bar.css('width', percent)
      $bar.text(percent)
    }
    return this
  }

  current() {

    let current = 0
    let $bar = this.$progress.find('.progress-bar')
    if ($bar) {
      current = parseInt($bar.attr('aria-valuenow'))
    }
    return current
  }

  capacity() {

    let capacity = 0
    let $bar = this.$progress.find('.progress-bar')
    if ($bar) {
      capacity = parseInt($bar.attr('aria-valuemax'))
    }
    return capacity
  }

  isCompleted() {

    let completed = false
    if (0 < this.current() && this.current() == this.capacity()) {
      completed = true
    }
    return completed
  }

  xhr() {

    let self = this
    return function () {
      self.show()
      let xhr = $.ajaxSettings.xhr()
      xhr.onprogress = function (p) {
        if (p.lengthComputable) {
          self.update(p.loaded, p.total)
        }
      }
      xhr.upload.onprogress = function (p) {
        if (p.lengthComputable) {
          self.update(p.loaded, p.total)
        }
      }
      return xhr
    }
  }
}
