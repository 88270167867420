'use strict'

let tms = {}
module.exports = tms

tms.getResourceTag = function(resourceTagId, done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/resource/tag/' + resourceTagId,
    dataType: 'json'
  }).then(done).fail(fail).always(always)
}

tms.getProduct = function(serialNumber, done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/product/' + serialNumber,
    dataType: 'json',
    cache : false
  }).then(done).fail(fail).always(always)
}

tms.getTidClientCertificate = function(id, done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/product/tidClientCertificate/' + id,
    dataType: 'json',
    cache : false
  }).then(done).fail(fail).always(always)
}

tms.getBrandCodeMap = function(done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/brandAvailabilityInformation/brandCodeMap/',
    dataType: 'json',
    cache : false
  }).then(done).fail(fail).always(always)
}

tms.getBrandAvailabilityStatusMap = function(done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/brandAvailabilityInformation/brandAvailabilityStatusMap/',
    dataType: 'json',
    cache : false
  }).then(done).fail(fail).always(always)
}
tms.getProgress = function(progressId, done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/progress/' + progressId,
    dataType: 'json'
  }).then(done).fail(fail).always(always)
}

tms.getProgressMessage = function(progressId, done = console.log, fail = console.log, always) {

  $.ajax({
    type: 'get',
    url: '/tms-web/progress/' + progressId + '/messages',
    dataType: 'json'
  }).then(done).fail(fail).always(always)
}

tms.startProgress = function(progressId, progress, done = console.log, fail = console.log, always) {

  let iid = window.setInterval(function() {
    tms.getProgress(progressId, function(body) {
      let completed = true
      if ('progress' in body) {
        completed = body.progress.completed
        progress.update(body.progress.current, body.progress.capacity)
      }
      if (completed) {
        done(body)
      }
    }, function(response) {
      fail(response)
    }, function() {
      window.clearInterval(iid)
      if (typeof always === 'function') {
        always()
      }
    })
  }, 500)
}
